import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import States from './States';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import WarrantyOrderOptions from './WarrantyOrderOptions';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import orderHelpers from '../../helpers/orders';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)


function EditOrderInfoPopup(props) {

    const selectedOrder = props.selectedOrder;
    
    const [date, setDate] = useState(dayjs.utc(selectedOrder.order_date));
    const [poNumber, setPoNumber] = useState(selectedOrder.internal_po_number);
    const [originalPoNumber, setOriginalPoNumber] = useState(selectedOrder.external_po_number);
    const [vendor, setVendor] = useState(selectedOrder.vendor);
    const [warehouse, setWarehouse] = useState(selectedOrder.warehouse);
    const [action, setAction] = useState(selectedOrder.warranty_issued ? selectedOrder.warranty_issued : '');
    const [name, setName] = useState(selectedOrder.customer_name);
    const [email, setEmail] = useState(selectedOrder.email);
    const [street, setStreet] = useState(selectedOrder.street);
    const [street2, setStreet2] = useState(selectedOrder.street2);
    const [city, setCity] = useState(selectedOrder.city);
    const [state, setState] = useState(selectedOrder.state);
    const [zipCode, setZipCode] = useState(selectedOrder.zip_code);
    const [reason, setReason] = useState(selectedOrder.reason);
    const [fastShipping, setFastShipping] = useState(selectedOrder.fast_shipping);
    const [type, setType] = useState(selectedOrder.order_type);
    const [vendorDropdownOptions, setVendorDropdownOptions] = useState([]);
    const [orderStatus, setOrderStatus] = useState(selectedOrder.order_status)

    useEffect(() => {
        determineVendorOptions({value: selectedOrder.order_type});
    }, []);

    const filter = createFilterOptions();

    const handleChange = (event) => {
        let func = `set${event.target.name[0].toUpperCase() + event.target.name.substring(1)}`;
        eval(func)(event.target.value);
    }

    const handleDateChange = (date, field) => {
        if(date !== null){
            setDate(dayjs.utc(date));
        } else {
            setDate(date);
        }
    }

    const dropdownStateOptions = States.map(value => ({ value: value, name: value}));
    const dropshipVendors = props.dropshipVendors?.map(value => ({ value: value, name: value}));
    const bulkVendors = props.bulkVendors?.map(value => ({ value: value, name: value}));
    const allVendors = props.allVendors?.map(value => ({ value: value, name: value}));

    const determineVendorOptions = (orderType) => {
        if (!orderType) {
            setVendorDropdownOptions(allVendors);
        } else if (orderType.value === 'Dropship') {
            setVendorDropdownOptions(dropshipVendors);
        } else if (orderType.value === 'Bulk') {
            setVendorDropdownOptions(bulkVendors);
        }
    }

    const requiredFields = [
        { field: 'order_date', message: 'Date is required.' },
        { field: 'internal_po_number', message: 'PO Number is required.' },
        { field: 'warranty_issued', message: 'Action is required.'},
        { field: 'reason', message: 'Reason is required.'},
        { field: 'customer_name', message: 'Name is required.'},
        { field: 'street', message: 'Street is required.'},
        { field: 'city', message: 'City is required.'},
        { field: 'state', message: 'State is required.'},
        { field: 'zip_code', message: 'Zip Code is required.'},
    ]
    
    const checkValidity = (orderInfo) => {
        let errorMessages = [];
        
        for (const { field, message } of requiredFields) {
            if (!orderInfo[field]) {
                errorMessages.push(message);
            }
        }
    
        if (errorMessages.length > 0) {
            const error_message = errorMessages.join('\n');
            props.toast.error(error_message);
            return false;
        }
        return true;
    };

    const updateWarrantyOrderInfo = async () => {
        props.setLoading(true);
        const orderInfo = {
            order_date: date ? date.get('year') + '-' + (date.get('month') + 1) + '-' + date.get('date') : null,
            internal_po_number: poNumber,
            external_po_number: originalPoNumber,
            order_status: orderStatus,
            order_type: type,
            vendor: vendor,
            warehouse: warehouse !== "" ? warehouse: null,
            warranty_issued: action,
            reason: reason, 
            fast_shipping: fastShipping,
            customer_name: name,
            email : email,
            street: street,
            street_2: street2,
            city: city,
            state: state,
            zip_code: zipCode
        }

        const databaseParams = {
            order_date: date !== null ? date.get('year') + '-' + (date.get('month') + 1) + '-' + date.get('date') : null,
            internal_po_number: poNumber !== "" ? poNumber : null,
            external_po_number: originalPoNumber !== "" ? originalPoNumber : null,
            order_status: orderStatus !== "" ? orderStatus : null,
            order_type: type !== "" ? type : null,
            vendor: vendor !== "" ? vendor : null,
            warehouse: warehouse !== "" ? warehouse : null,
            warranty_issued: action !== "" ? action : null,
            reason: reason !== "" ? reason : null, 
            fast_shipping: fastShipping !== "" ? fastShipping : null, 
            customer_name: name !== "" ? name : null,
            email: email !== "" ? email : null,
            street: street !== "" ? street : null,
            street_2: street2 !== "" ? street2 : null,
            city: city !== "" ? city : null,
            state: state !== "" ? state : null,
            zip_code: zipCode !== "" ? zipCode : null,
        }
        if (!checkValidity(orderInfo)) {
            props.setLoading(false);
            return;
        }
        try {
            if(window.confirm(`Confirm changes for Warranty Order ${props.po}`)) {
                const res = await orderHelpers.updateWarrantyOrderInfo(props.company, databaseParams, props.token);
                if(res) {
                    props.updateOrderState(orderInfo);
                    props.toast.success('Saved Changes!');
                    props.handleClose();
                } else {
                    props.toast.error('Failed to Save Changes!');
                } 
            }
        }
        catch(err) {
            props.toast.error('Failed to Save Changes!');
        }
        finally {
            props.setLoading(false);
        }
    }

    return(
        <Dialog open = {props.open} onClose = {()=>props.handleClose()} fullWidth = {true} maxWidth = {'xl'}>
            <Box sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
                <DialogTitle>
                        Edit Warranty Order {props.internal_po_number}
                </DialogTitle>
                <Button style = {{marginLeft: "auto", marginRight: "5%", width: "10%"}} onClick = {()=>props.handleClose()} disabled = {props.isLoading} endIcon = {<CloseIcon/>} color = "error">Cancel</Button>
            </Box>
            <DialogContent>
                <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'column'}}>
                    <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
                        <label>Order</label>
                    </Box>
                    <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
                        <FormControl sx = {{ mr: '0.6%', width: '15%' }}>
                            <LocalizationProvider dateAdapter = {AdapterDayjs}>
                                <DatePicker
                                    label = "Date"
                                    name = 'Date'
                                    onChange = {(event) => handleDateChange(event, 'date')}
                                    value = {date ? date : null}
                                    clearable = {true}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <TextField 
                            name = 'poNumber'
                            label = 'PO Number'
                            onChange = {handleChange}
                            value = {poNumber}
                            disabled
                            sx = {{width: '15%', mr: '0.6%'}}
                            required
                        />
                        <TextField 
                            name = 'originalPoNumber'
                            label = 'Original PO Number'
                            onChange = {handleChange}
                            value = {originalPoNumber}
                            sx = {{width: '15%', mr: '0.6%'}}
                        />
                        <FormControl sx = {{ width: '15%' , mr: '0.6%' }}>
                            <AutoComplete
                                value = {type}
                                onChange = {(event, newValue) => {
                                    determineVendorOptions(newValue);
                                    if (typeof newValue === 'string') {
                                        setType({value: newValue,});
                                    } else if (newValue && newValue.inputValue) {
                                        setType({value: newValue.inputValue,});
                                    } else {
                                        setType(newValue ? newValue.value : null);
                                    }
                                }}
                                filterOptions = {(options, params) => {
                                    const filtered = filter(options, params);
                                    return filtered;
                                }}
                                isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id = "type"
                                options = {WarrantyOrderOptions.type}
                                getOptionLabel = {(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.value;
                                }}
                                renderOption = {(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                    <li key = {key} {...optionProps}>
                                        {option.value}
                                    </li>
                                    );
                                }}
                                renderInput = {(params) => (
                                    <TextField {...params} label = "Type" />
                                )}
                            />
                        </FormControl>
                        <FormControl sx = {{ width: '15%', mr: '0.6%' }}>
                            <AutoComplete
                                value = {vendor}
                                onChange = {(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setVendor({value: newValue,});
                                    } else if (newValue && newValue.inputValue) {
                                        setVendor({value: newValue.inputValue,});
                                    } else {
                                        setVendor(newValue ? newValue.value : null);
                                    }
                                }}
                                filterOptions = {(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;
                                    const isExisting = options.some((option) => inputValue === option.value);
                                    if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        value: `Select "${inputValue}"`
                                    });
                                    }
                                    return filtered;
                                }}
                                isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id = "vendor"  
                                options = {vendorDropdownOptions} 
                                getOptionLabel = {(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.value;
                                }}
                                renderOption = {(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                    <li key={key} {...optionProps}>
                                        {option.value}
                                    </li>
                                    );
                                }}
                                renderInput = {(params) => (
                                    <TextField {...params} label="Vendor" />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                            <AutoComplete
                                value = {warehouse}
                                onChange = {(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setWarehouse({value: newValue,});
                                    } else if (newValue && newValue.inputValue) {
                                        setWarehouse({value: newValue.inputValue,});
                                    } else {
                                        setWarehouse(newValue ? newValue.value : null);
                                    }
                                }}
                                filterOptions = {(options, params) => {
                                    const filtered = filter(options, params);
                                    return filtered;
                                }}
                                isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id = "warehouse"
                                options = {WarrantyOrderOptions.warehouses}
                                getOptionLabel = {(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.value;
                                }}
                                renderOption = {(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                    <li key = {key} {...optionProps}>
                                        {option.value}
                                    </li>
                                    );
                                }}
                                renderInput = {(params) => (
                                    <TextField {...params} label = "Original Warehouse" />
                                )}
                            />
                        </FormControl>
                        <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                            <InputLabel id = "actionInput">Action</InputLabel>
                            <Select
                                name = 'action'   
                                label = "Action"
                                value = {action}        
                                onChange = {handleChange}
                            >
                                {WarrantyOrderOptions.action.map((action, index) => {
                                    let name = action.name;
                                    
                                    return(
                                        <MenuItem key = {index} value = {action.value}>{name}</MenuItem>
                                    )
                                }
                                )}
                            </Select>
                        </FormControl>
                        <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                            <AutoComplete
                                value = {reason}
                                onChange = {(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setReason({value: newValue,});
                                    } else if (newValue && newValue.inputValue) {
                                        setReason({value: newValue.inputValue,});
                                    } else {
                                        setReason(newValue ? newValue.value : null);
                                    }
                                }}
                                filterOptions = {(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;
                                    const isExisting = options.some((option) => inputValue === option.value);
                                    if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        value: `Select "${inputValue}"`
                                    });
                                    }
                                    return filtered;
                                }}
                                isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id = "reason"
                                options = {WarrantyOrderOptions.reasons}
                                getOptionLabel = {(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.value;
                                }}
                                renderOption = {(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                    <li key = {key} {...optionProps}>
                                        {option.value}
                                    </li>
                                    );
                                }}
                                renderInput = {(params) => (
                                    <TextField {...params} label = "Reason" />
                                )}
                            />
                        </FormControl>
                        <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                            <AutoComplete
                                value = {fastShipping}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setFastShipping({value: newValue,});
                                    } else if (newValue && newValue.inputValue) {
                                        setFastShipping({value: newValue.inputValue,});
                                    } else {
                                        setFastShipping(newValue ? newValue.value : null);
                                    }
                                }}
                                filterOptions = {(options, params) => {
                                    const filtered = filter(options, params);
                                    return filtered;
                                }}
                                isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id = "fastShipping"
                                options = {WarrantyOrderOptions.fast_shipping}
                                getOptionLabel = {(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.value;
                                }}
                                renderOption = {(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                    <li key = {key} {...optionProps}>
                                        {option.value}
                                    </li>
                                    );
                                }}
                                renderInput = {(params) => (
                                    <TextField {...params} label = "Fast Shipping" />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mt: '25px', mb: '10px'}}>
                        <label>Address</label>
                    </Box>
                    <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
                        <TextField 
                            name = 'name'
                            label = 'Name'
                            onChange = {handleChange}
                            value = {name}
                            sx = {{mr: '0.6%'}}
                            style = {{width: '38.4%'}}
                        />
                        <TextField 
                            name = 'email'
                            label = 'Email'
                            onChange = {handleChange}
                            value = {email}
                            sx = {{mr: '0.6%'}}
                            style = {{width: '38.4%'}}
                        />
                    </Box>
                    <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
                        <TextField 
                                name = 'street'
                                label = 'Street'
                                onChange = {handleChange}
                                value = {street}
                                sx = {{width: '15%', mr: '0.6%'}}
                        />
                        <TextField 
                                name = 'street2'
                                label = 'Street 2'
                                onChange = {handleChange}
                                value = {street2}
                                sx = {{width: '15%', mr: '0.6%'}}
                        />
                        <TextField 
                                name = 'city'
                                label = 'City'
                                onChange = {handleChange}
                                value = {city}
                                sx = {{width: '15%', mr: '0.6%'}}
                        />
                        <FormControl sx = {{ width: '15%', mr: '0.6%' }}>
                            <AutoComplete
                                value = {state}
                                onChange = {(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setState({value: newValue,});
                                    } else if (newValue && newValue.inputValue) {
                                        setState({value: newValue.inputValue,});
                                    } else {
                                        setState(newValue ? newValue.value : null);
                                    }
                                }}
                                filterOptions = {(options, params) => {
                                    const filtered = filter(options, params);
                                    return filtered;
                                }}
                                isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id = "state"
                                options = {dropdownStateOptions}
                                getOptionLabel = {(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.value;
                                }}
                                renderOption = {(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                    <li key = {key} {...optionProps}>
                                        {option.value}
                                    </li>
                                    );
                                }}
                                renderInput = {(params) => (
                                    <TextField {...params} label = "State" />
                                )}
                            />
                        </FormControl>
                        <TextField 
                            name = 'zipCode'
                            label = 'Zip Code'
                            onChange = {handleChange}
                            value = {zipCode}
                            sx = {{mr: '0.6%'}}
                            style = {{width: '15%'}}
                        />
                    </Box>
                </Box>             
                </DialogContent>
                <DialogActions>
                    <Box display = "flex" sx = {{ mb: 5}} style = {{ width: "100%"}}>
                        <Button variant = "contained" style = {{marginLeft: "auto", marginRight: "5%", width: "10%"}} onClick = {()=>updateWarrantyOrderInfo()} disabled = {props.isLoading} color = "success" endIcon = {<SaveIcon />}>Save</Button>
                    </Box>
                </DialogActions>
        </Dialog>
    )
}

export default EditOrderInfoPopup;