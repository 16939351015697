import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import WarrantyOrderQueryForm from './WarrantyOrderQueryForm'
import WarrantyOrderSearchTable from './WarrantyOrderSearchTable';
import orderHelpers from '../../helpers/orders';
import vendorHelpers from '../../helpers/vendors';
import OrderDetails from './OrderDetails';

function WarrantyOrderSearch(props) {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [poNumber, setPoNumber] = useState('');
    const [name, setName] = useState('');
    const [skuList, setSkuList] = useState([]);
    const [sku, setSku] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [orderDetails, setOrderDetails] = useState([]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [direction, setDirection] = useState(null);
    const [orderStatus, setOrderStatus] = useState('');
    const [dropshipVendorList, setDropshipVendorList] = useState([]);
    const [bulkVendorList, setBulkVendorList] = useState([]);
    const [allVendorList, setAllVendorList] = useState([]);
    
    useEffect(() => {

        Promise.all([vendorHelpers.getVendors(props.company, props.token), vendorHelpers.getBulkVendors(props.company + "_bulk", props.token)]).then(responses => {
            const dropshipVendorList = responses[0].vendors;
            setDropshipVendorList(dropshipVendorList);
            const bulkVendorList = responses[1].vendors;
            setBulkVendorList(bulkVendorList);
            const allVendorSet = new Set([...dropshipVendorList, ...bulkVendorList]);
            const allVendorList = Array.from(allVendorSet);
            setAllVendorList(allVendorList);
        })

        getDropshipSkus();
        getWarrantyOrderDetails();

    }, []);

    useEffect(() => {

        if (selectedOrder.internal_po_number) {
            updateSelectedOrder(selectedOrder.internal_po_number);
        }

    }, [orderDetails]);

    const handleChange = (event) => {
        let func = `set${event.target.name[0].toUpperCase() + event.target.name.substring(1)}`;
        eval(func)(event.target.value);
    }

    const handleDateChange = (date, field) => {
        let func = setStartDate;
        if(field === "endDate") func = setEndDate;
        if(date !== null){
            func({formatted: date.format("YYYY-MM-DD"), full: date});
        } else {
            func(date);

        }
    }

    const getDropshipSkus = async () => {
        props.setLoading(true);
        try {
            const dropshipSkusResponse = await orderHelpers.getDropshipItems(props.company, props.token);
            const dropshipSkus = dropshipSkusResponse.items;
            const mappedSkus = dropshipSkus.map(value => ({ value: value, name: value}));
            setSkuList(mappedSkus);
        }
        catch(err) {
            console.log(err);
        }
        props.setLoading(false);
    }

    const getWarrantyOrderDetails = async () => {
        props.setLoading(true);
        const searchParams = {
            start_date: startDate !== null ? startDate.formatted : null,
            end_date: endDate !== null ? endDate.formatted : null,
            internal_po_number: poNumber !== "" ? poNumber : null,
            order_status: orderStatus !== "" ? orderStatus : null,
            internal_sku: sku !== "" ? sku : null,
            customer_name: name !== "" ? name : null,
            order_by: 'order_date',
            limit: 1000
        }
        try {
            const warranty_orders = await orderHelpers.getWarrantyOrderDetails(props.company, searchParams, props.token);
            setOrderDetails(warranty_orders);
        }
        catch(err) {
            props.toast.error('Failed to Search!');
        }
        finally {
            props.setLoading(false);
        }
    }

    const updateSelectedOrder = (internal_po_number) => {
        const itemInfo = [];
        orderDetails.forEach((orderLine) => {
            if (orderLine.internal_po_number === internal_po_number) {
                itemInfo.push({original_sku: orderLine.original_sku,
                                internal_sku: orderLine.internal_sku,
                                quantity: orderLine.quantity,
                                lot_number: orderLine.lot_number,
                                warehouse_notes: orderLine.warehouse_notes,
                                notes: orderLine.notes,
                                warranty_id: orderLine.warranty_id
                });
            }
        })
        const orderInfo = orderDetails.find(orderDetail => orderDetail.internal_po_number === internal_po_number);
        if (!orderInfo) {
            console.log('unexpectedly failed to find selected order');
        } else {
            const warranty_object = {
                order_date: orderInfo.order_date,
                order_status: orderInfo.order_status,
                internal_po_number: orderInfo.internal_po_number,
                external_po_number: orderInfo.external_po_number,
                order_type: orderInfo.order_type,
                vendor: orderInfo.vendor,
                warehouse: orderInfo.warehouse,
                warranty_issued: orderInfo.warranty_issued,
                reason: orderInfo.reason, 
                fast_shipping: orderInfo.fast_shipping, 
                customer_name: orderInfo.customer_name,
                email: orderInfo.email,
                street: orderInfo.street,
                street_2: orderInfo.street_2,
                city: orderInfo.city,
                state: orderInfo.state,
                zip_code: orderInfo.zip_code,
                items: itemInfo
            }
            setSelectedOrder(warranty_object);
        }
    }

    const clearOrderSearch = async () => {
        setStartDate(null);
        setEndDate(null);
        setOrderStatus('');
        setPoNumber('');
        setName('');
        setSku(null);
        const warranty_orders = await orderHelpers.getWarrantyOrderDetails(props.company, {order_by: 'order_date', limit: 1000}, props.token);
        setOrderDetails(warranty_orders);
    }

    const setOpenDetails = async(selectedOrder = {}) => {
        if(Object.keys(selectedOrder)?.length === 0 ) {
            setSelectedOrder(selectedOrder);
            setSelectedItemIndex(0);
        }
        else {
            const this_internal_po_number = selectedOrder.row?.internal_po_number;
            updateSelectedOrder(this_internal_po_number);
            let selectedTabIndex = -1;

            orderDetails.forEach((orderLine) => {
                if (orderLine.internal_po_number === this_internal_po_number) {
                    selectedTabIndex++;
                    if (orderLine.internal_sku === selectedOrder.row?.internal_sku) {
                        setSelectedItemIndex(selectedTabIndex);
                    }
                }
            });
        }
    }

    const updateOrderState = (orderInfo) => {
        const newOrders = [];
        orderDetails.forEach(orderLine => {
            if (orderLine.internal_po_number === orderInfo.internal_po_number) {
                newOrders.push({
                    ...orderLine,
                    ...orderInfo
                });
            } else {
                newOrders.push(orderLine);
            }
        })
        setOrderDetails(newOrders);
    }

    const updateItemState = (itemInfo) => {
        const newOrders = [];
        orderDetails.forEach(orderLine => {
            if (orderLine.warranty_id === itemInfo.warranty_id) {
                newOrders.push({
                    ...orderLine,
                    ...itemInfo
                });
            } else {
                newOrders.push(orderLine);
            }
        })
        setOrderDetails(newOrders);
    }

    const exportOrders = async() => {
        const today = new Date();
        const formattedDate = `${today.getMonth() + 1}.${today.getDate()}.${today.getFullYear().toString().slice(-2)}`;
        let data = [
            ["order_date", "internal_po_number", "original_po_number" , "vendor", "original_warehouse", "action", "reason", 
            "fast_shipping", "original_sku", "sku", "quantity", "lot_number", "customer_name", "email", "street", "street_2", "city", 
            "state", "zip_code", "warehouse_notes", "notes"].join(",")
        ];
        for(let order of orderDetails) {
            data.push(
                [order.order_date, order.internal_po_number, order.external_po_number, order.vendor, order.warehouse, 
                order.warranty_issued, order.reason, order.fast_shipping, order.original_sku, order.internal_sku, 
                order.quantity, order.lot_number, order.customer_name, order.email, order.street, order.street_2, order.city, 
                order.state, order.zip_code, order.warehouse_notes, order.notes].join(",")
            );
        }
        let csv = data.join("\n");
        let uri = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", `${formattedDate} WarrantyOrderSearchExport.csv`);
        document.body.appendChild(a);
        a.click();
    }

    return(
        <Box className = {props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
            <OrderDetails 
                skuList = {skuList}
                updateOrderState = {updateOrderState}
                updateItemState = {updateItemState}
                {...props} 
                direction = {direction} 
                setSelectedItemIndex = {setSelectedItemIndex}
                tab = {selectedItemIndex} 
                selectedOrder = {selectedOrder} 
                setOpenDetails = {setOpenDetails}
                dropshipVendors = {dropshipVendorList}
                bulkVendors = {bulkVendorList}  
                allVendors = {allVendorList}
            />
            <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{paddingLeft: 2}}>
                <h1>Warranty Order Search</h1>
                <WarrantyOrderQueryForm
                    isMobile = {props.isMobile}
                    poNumber = {poNumber}
                    name = {name}
                    skuList = {skuList}
                    sku = {sku}
                    setSku = {setSku}
                    handleChange = {handleChange}
                    handleDateChange = {handleDateChange}
                    startDate = {startDate?.full}
                    endDate = {endDate?.full}
                    getWarrantyOrderDetails = {getWarrantyOrderDetails}
                    orderStatus = {orderStatus}
                    setOrderStatus = {setOrderStatus}
                    clearOrderSearch = {clearOrderSearch}
                />
                <WarrantyOrderSearchTable 
                    isMobile = {props.isMobile}
                    orderDetails = {orderDetails}
                    setOpenDetails = {setOpenDetails}
                    exportOrders = {exportOrders}
                />
            </Box>
        </Box>
    )
}

export default WarrantyOrderSearch;