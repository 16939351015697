const warehouses = [
    {value: "Kentucky", name: "Kentucky"},
    {value: "Pennsylvania", name: "Pennsylvania"}
]

const action = [
    {value: "Yes", name: "Yes"},
    {value: "Yes - WFW", name: "Yes - WFW"},
    {value: "No", name: "No"},
]

const fast_shipping = [
    {value: "Yes", name: "Yes"},
    {value: "No", name: "No"}
]

const reasons = [
    {value: 'Broken Parts', name: 'Broken Parts'},
    {value: 'Chemical Smell', name: 'Chemical Smell'},
    {value: 'Discolored Foam/Bolster', name: 'Discolored Foam/Bolster'},
    {value: 'Dye Issue', name: 'Dye Issue'},
    {value: 'Exchange', name: 'Exchange'},
    {value: 'Fabric Damage After Use', name: 'Fabric Damage After Use'},
    {value: 'Fabric Damage Upon Receipt', name: 'Fabric Damage Upon Receipt'},
    {value: 'Foam/Bolster Did Not Recover', name: 'Foam/Bolster Did Not Recover'},
    {value: 'Glue Issue', name: 'Glue Issue'},
    {value: 'Incorrect Address', name: 'Incorrect Address'},
    {value: 'Label Created Status', name: 'Label Created Status'},
    {value: 'Missing Item', name: 'Missing Item'},
    {value: 'Missing Parts', name: 'Missing Parts'},
    {value: 'Missing Parts Prepackaged', name: 'Missing Parts Prepackaged'},
    {value: 'New Dimensions', name: 'New Dimensions'},
    {value: 'Package Did Not Arrive', name: 'Package Did Not Arrive'},
    {value: 'Packing Issue', name: 'Packing Issue'},
    {value: 'Seam/Sewing Issue', name: 'Seam/Sewing Issue'},
    {value: 'Seller Related Problem', name: 'Seller Related Problem'},
    {value: 'Shedding/Peeling', name: 'Shedding/Peeling'},
    {value: 'Unavailable For Individual Purchase', name: 'Unavailable For Individual Purchase'},
    {value: 'Under/Overstuffed', name: 'Under/Overstuffed'},
    {value: 'Used Inventory', name: 'Used Inventory'},
    {value: 'Wrong Bolster', name: 'Wrong Bolster'},
    {value: 'Wrong Cover', name: 'Wrong Cover'},
    {value: 'Wrong Foam', name: 'Wrong Foam'},
    {value: 'Wrong Item', name: 'Wrong Item'},
    {value: 'Zipper Issue After Use', name: 'Zipper Issue After Use'},
    {value: 'Zipper Issue Upon Receipt', name: 'Zipper Issue Upon Receipt'}
]

const type = [
    {value: "Dropship", name: "Dropship"},
    {value: "Bulk", name: "Bulk"}
]

const order_status = [
    {value: 1, name: 'Warranty Created'},
    {value: 100, name: 'Order Created'},
    {value: 199, name: 'Order Cancelled'}
]

const WarrantyOrderOptions = {
    warehouses,
    action,
    fast_shipping,
    reasons,
    type,
    order_status
};

export default WarrantyOrderOptions;