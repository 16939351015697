import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import orderHelpers from '../../helpers/orders';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import EditItemPopup from './EditItemPopup'
import EditOrderInfoPopup from './EditOrderInfoPopup'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WarrantyOrderOptions from './WarrantyOrderOptions';
import Tooltip from '@mui/material/Tooltip';

function OrderDetails(props) { 

    const selectedOrder = props.selectedOrder || {};
    const [openEditItemPopup, setOpenEditItemPopup] = useState(false);
    const [openEditOrderInfoPopup, setOpenEditOrderInfoPopup] = useState(false);

    const cancelWarrantyOrder = async() => {
        try{
            if(!window.confirm(`Cancel PO# ${selectedOrder.internal_po_number}?`)) {
                return props.toast.error('Operation Aborted!');
            }
            props.setLoading(true);
            let res = await orderHelpers.cancelWarrantyOrder(props.company, selectedOrder.internal_po_number, props.token);
            if (res === true) {
                props.toast.success('Order Cancelled!');
                props.updateOrderState({
                    ...selectedOrder,
                    order_status: 199,
                });
            } 
            else {
                props.toast.error("Failed To Cancel Order!");
            } 
        }
        catch(e){
            throw Error(e);
        }
        finally{
            props.setLoading(false);
        }
    }

    const getStatusName = (status_value) => {
        const status = WarrantyOrderOptions.order_status.find(status => status.value === status_value);
        return status ? status.name : "N/A";
    }
    
    return (
        <Drawer open = {Object.keys(selectedOrder).length > 0} onClose = {() => props.setOpenDetails()} anchor = "bottom">
            {openEditItemPopup ? <EditItemPopup
                open = {openEditItemPopup} 
                handleClose = {() => setOpenEditItemPopup(false)} 
                item = {selectedOrder.items[props.tab]} 
                internal_po_number = {selectedOrder.internal_po_number}
                skuList = {props.skuList} 
                setLoading = {props.setLoading}
                toast = {props.toast}
                company = {props.company}
                token = {props.token}
                updateItemState = {props.updateItemState}
                /> : null}
            {openEditOrderInfoPopup ? <EditOrderInfoPopup
                {...props}
                open = {openEditOrderInfoPopup} 
                handleClose = {() => setOpenEditOrderInfoPopup(false)} 
                po = {selectedOrder.internal_po_number} 
                vendor_name = {selectedOrder.vendor} 
                /> : null}
            {Object.keys(selectedOrder).length > 0 && 
                <Box style = {{height: "60vh"}}  display = "flex" sx = {{m:"auto", mb:"80px", pt:"20px"}}>
                    {/* Order Information */}
                    <Card style = {{height: "60vh"}} sx = {{ margin: 2, width: "30vw",  mr:"10px" }}>
                        <CardContent>
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: -2 }}>
                                <Typography gutterBottom variant="h5" component="div" >
                                    Order Information
                                </Typography>
                                <Box sx = {{ display: 'flex', alignItems: 'center',  mr:"5px"}}>
                                    <Chip
                                        label = "Dropship"
                                        variant = {selectedOrder.order_type === 'Dropship' ? 'filled' : 'outlined'}
                                        sx = {{
                                            mr: 1, 
                                            bgcolor: selectedOrder.order_type === 'Dropship' ? '#868f84' : 'default',
                                            color:  selectedOrder.order_type === 'Dropship' ? 'white' : '#eef1ee',
                                            borderColor: selectedOrder.order_type === 'Dropship' ? '#868f84' : '#eef1ee'
                                        }}
                                    />
                                    <Chip
                                        label = "Bulk"
                                        variant = {selectedOrder.order_type === 'Bulk' ? 'filled' : 'outlined'}
                                        sx = {{
                                            mr: 1, 
                                            bgcolor: selectedOrder.order_type === 'Bulk' ? '#868f84' : 'default',
                                            color:  selectedOrder.order_type === 'Bulk' ? 'white' : '#eef1ee',
                                            borderColor: selectedOrder.order_type === 'Bulk' ? '#868f84' : '#eef1ee'
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box sx = {{ mt: -6, p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'text.secondary' }}>
                                <Typography gutterBottom variant="body2" component="div" >
                                    {selectedOrder.order_date ? selectedOrder.order_date.substring(0, 10) : "N/A"}
                                </Typography>
                            </Box>
                            <Divider sx = {{ width: "100%", opacity: 0.6, mt: -2 }} variant = "fullwidth" />
                            <Box sx = {{ p: "20px", width: "100%" }}>
                                <List dense>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary="PO Number"
                                            secondary = {selectedOrder.internal_po_number ? selectedOrder.internal_po_number : "N/A" }
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,  
                                                paddingRight: 2, 
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Original PO Number"
                                            secondary = {selectedOrder.external_po_number ? selectedOrder.external_po_number : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,  
                                                paddingRight: 2, 
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Vendor"
                                            secondary = {selectedOrder.vendor ? selectedOrder.vendor : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,  
                                                paddingRight: 2, 
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Original Warehouse"
                                            secondary = {selectedOrder.warehouse ? selectedOrder.warehouse : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,  
                                                paddingRight: 2, 
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Reason"
                                            secondary = {selectedOrder.reason ? selectedOrder.reason : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,  
                                                paddingRight: 2, 
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Status"
                                            secondary = {getStatusName(selectedOrder.order_status)}
                                            secondaryTypographyProps = {{
                                                sx: {
                                                    color: selectedOrder.order_status === 1 ? '#868f84' : selectedOrder.order_status === 199 ? '#dc9e96' : 'inherit',
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,  
                                                paddingRight: 2, 
                                            }}
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                        </CardContent>    
                    </Card>
                    {/* Shipment Information */}
                    <Card style = {{height: "60vh"}} sx = {{ margin: 2, width: "20vw",  mr:"10px" }}>
                        <CardContent>
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: -2}}>
                                <Typography gutterBottom variant = "h5" component = "div" >
                                    Shipment Information
                                </Typography>
                                <Typography gutterBottom variant =" body2" component = "div" >
                                    &nbsp;
                                </Typography>
                            </Box>
                            <Divider sx = {{ width: "100%", opacity: 0.6 }} variant = "fullWidth" />
                            <Box sx = {{ p: "20px", width: "100%" }}>
                                <List dense>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Action"
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}
                                        />
                                        <Chip 
                                            sx = {{mt: -.5, 
                                                bgcolor: selectedOrder.warranty_issued === "Yes" || selectedOrder.warranty_issued === "Yes - WFW" ? "#868f84" : "#dc9e96",
                                                color: 'white'}}
                                            size = 'small'
                                            label = {selectedOrder.warranty_issued ? selectedOrder.warranty_issued : "N/A"}
                                            variant = "filled"
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Fast Shipping"
                                            sx = {{
                                                mt: -.5,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,  
                                                paddingRight: 2
                                            }}
                                        />
                                        <Chip 
                                            sx = {{mt: -.5, 
                                                bgcolor: selectedOrder.fast_shipping === "Yes" || selectedOrder.fast_shipping === "Yes - WFW" ? "#868f84" : "#dc9e96",
                                                color: 'white'}}
                                            size = 'small'
                                            label = {selectedOrder.fast_shipping ? selectedOrder.fast_shipping : "N/A"}
                                            variant = "filled"
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                            <Divider sx = {{ width: "100%", opacity: 0.6, mt: -2 }} variant = "fullWidth" />
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography gutterBottom variant = "h5" component = "div" >
                                    Address
                                </Typography>
                            </Box>
                            <Divider sx = {{ width: "100%", opacity: 0.6, mt: -2}} variant = "fullWidth" />
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'text.secondary', mt: -1}}>
                                {selectedOrder.customer_name} <br />
                                {selectedOrder.email} <br />
                                {selectedOrder.street} <br />
                                {selectedOrder.street_2 && <>{selectedOrder.street_2}<br /></>}
                                {selectedOrder.city}, {selectedOrder.state} {selectedOrder.zip_code} <br />
                            </Box>
                        </CardContent>
                    </Card>
                    {/* Item Information */}
                    <Card style = {{height: "60vh"}} sx = {{ margin: 2, width: "32vw",  mr:"10px" }}>
                        <CardContent>
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: -2.5, mb: -3.8}}>
                                <Typography gutterBottom variant = "h5" component = "div" >
                                    Item Information
                                </Typography>
                            </Box>
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: -2.5}}>
                                <Tabs 
                                    value = {props.tab} 
                                    onChange = {(event, newValue) => props.setSelectedItemIndex(newValue)}
                                    variant = "scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label = "scrollable force tabs example"
                                    textColor = "inherit"
                                    sx = {{
                                        mt: -4,
                                        '& .MuiTabs-indicator': {
                                        backgroundColor: '#889285',
                                        },
                                        '& .MuiTab-root': {
                                        color: '#c2c4c1',
                                        },
                                        '& .Mui-selected': {
                                        color: '#889285',
                                        },
                                    }}>
                                    {selectedOrder.items?.map((item, index) => {
                                        return(
                                                <Tab value = {index} name = {index} key = {index} label = {`Item ${index + 1}`} />
                                        )
                                    })}
                            </Tabs>
                            </Box>
                            <Divider sx = {{ width: "100%", opacity: 0.6 }} variant = "fullWidth" />
                            <Box sx = {{ p: "20px", width: "100%" }}>
                                <List dense>
                                <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Original Item"
                                            secondary = {selectedOrder?.items[props.tab]?.original_sku ? selectedOrder?.items[props.tab]?.original_sku : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Item"
                                            secondary = {selectedOrder?.items[props.tab]?.internal_sku ? selectedOrder?.items[props.tab]?.internal_sku : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Quantity"
                                            secondary = {selectedOrder?.items[props.tab]?.quantity ? selectedOrder?.items[props.tab]?.quantity : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Lot Number"
                                            secondary = {selectedOrder?.items[props.tab]?.lot_number ? selectedOrder?.items[props.tab]?.lot_number : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Warehouse Notes"
                                            secondary = {selectedOrder?.items[props.tab]?.warehouse_notes ? selectedOrder?.items[props.tab]?.warehouse_notes : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Notes"
                                            secondary = {selectedOrder?.items[props.tab]?.notes ? selectedOrder?.items[props.tab]?.notes : "N/A"}
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                        </CardContent>
                    </Card>
                    <Box sx = {{ paddingTop: "20px"}}>
                        <Stack direction = "column" spacing = {2}>
                            <Tooltip title = {selectedOrder.order_status !== 1 ? "CANNOT EDIT ORDER!" : ""}>
                                <span>
                                    <Button 
                                        variant = "outlined" 
                                        endIcon = {<EditIcon />} 
                                        onClick = {()=>setOpenEditOrderInfoPopup(true)} 
                                        sx = {{
                                            fontWeight: 'bold',
                                            width: '150px',
                                            color: selectedOrder.order_status === 1 ? '#7c8978' : '#e3e3e3', 
                                            borderColor: selectedOrder.order_status === 1 ? '#7c8978' : '#f8f8f8', 
                                            backgroundColor: selectedOrder.order_status === 1 ? '#d9e1d7' : '#f8f8f8',
                                            '&:hover': {
                                                color: selectedOrder.order_status === 1 ? 'white' : '#e3e3e3',
                                                backgroundColor: selectedOrder.order_status === 1 ? '#868f84' : '#f8f8f8',
                                                borderColor: selectedOrder.order_status === 1 ? '#868f84' : '#f8f8f8',
                                            },
                                            }}
                                        disabled = {selectedOrder.order_status !== 1}
                                        >
                                        Edit Order
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title = {selectedOrder.order_status !== 1 ? "CANNOT EDIT ITEM!" : ""}>
                                <span>
                                    <Button 
                                        variant = "outlined" 
                                        endIcon = {<EditIcon />} 
                                        onClick = {()=>setOpenEditItemPopup(true)} 
                                        sx = {{
                                            fontWeight: 'bold',
                                            width: '150px',
                                            color: selectedOrder.order_status === 1 ? '#7c8978' : '#e3e3e3', 
                                            borderColor: selectedOrder.order_status === 1 ? '#7c8978' : '#f8f8f8', 
                                            backgroundColor: selectedOrder.order_status === 1 ? '#d9e1d7' : '#f8f8f8',
                                            '&:hover': {
                                                color: selectedOrder.order_status === 1 ? 'white' : '#e3e3e3',
                                                backgroundColor: selectedOrder.order_status === 1 ? '#868f84' : '#f8f8f8',
                                                borderColor: selectedOrder.order_status === 1 ? '#868f84' : '#f8f8f8',
                                            },
                                            }}
                                        disabled = {selectedOrder.order_status !== 1}
                                        >
                                        Edit Item
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title = {selectedOrder.order_status !== 1 ? "CANNOT CANCEL ORDER!" : ""}>
                                <span>
                                    <Button 
                                        variant = "outlined" 
                                        endIcon = {<DeleteForeverIcon />} 
                                        onClick = {()=>cancelWarrantyOrder(true)} 
                                        sx = {{
                                            fontWeight: 'bold',
                                            width: '150px',
                                            color: selectedOrder.order_status === 1 ? '#96605a' : '#e3e3e3', 
                                            borderColor: selectedOrder.order_status === 1 ? '#96605a' : '#f8f8f8', 
                                            backgroundColor: selectedOrder.order_status === 1 ? '#dc9e96' : '#f8f8f8',
                                            '&:hover': {
                                                color: selectedOrder.order_status === 1 ? 'white' : '#e3e3e3',
                                                backgroundColor: selectedOrder.order_status === 1 ? '#96605a' : '#f8f8f8',
                                                borderColor: selectedOrder.order_status === 1 ? '#96605a' : '#f8f8f8',
                                            },
                                            }}
                                        disabled = {selectedOrder.order_status !== 1}
                                        >
                                        Cancel Order
                                    </Button>
                                </span>
                            </Tooltip>
                        </Stack>
                    </Box>
                </Box>
            }
        </Drawer>
    )

}

export default OrderDetails;


