import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import bulkHelpers from '../../helpers/bulk';
import BulkTable from './BulkTable';
import BulkOrderDetails from './BulkOrderDetails';
import BulkUpdatePopup from './Shipping/BulkUpdatePopup';
import BulkExportPopup from './BulkExportPopup';
import DecisionImport from './DecisionImport';

function BulkOrderTableTemplate(props) {
    const [rows, setRows] = useState([]);
    const [refresh, setRefresh] = useState(false);
    // const [selectedRows, setSelectedRows] = useState([]);
    const [openDetails, setOpenDetails] = useState(false);
    const [openBulkUpdate, setOpenBulkUpdate] = useState(false);
    const [openExport, setOpenExport] = useState(false);

    const company = props.company + "_bulk";
    const warehouse = props.warehouse;
    const token = props.token;
    const setLoading = props.setLoading;

    useEffect(() => {
        const getOrders = async() => {
            setLoading(true)
            let body = {
                status: props.status
            };
            let whs = [];
            if(props.warehouse.toLowerCase() !== "all") whs.push(props.warehouse);
            else {
                whs.push("Kentucky");
                whs.push("Pennsylvania");
                whs.push("Illinois");
            }
            let orders = [];
            if(props.status === "New") {
                let req = await bulkHelpers.getBulkOrdersTable(company, body, token);
                if(req?.length > 0)
                    orders = orders.concat(req);
            }
            else {
                for(let wh of whs) {
                    console.log("orders", orders)
                    body.warehouse = wh;
                    let req = await bulkHelpers.getBulkOrdersTable(company, body, token);
                    if(req?.length > 0)
                        orders = orders.concat(req);
                }
            }
            console.log("orders", orders)
            if(!orders || orders.length === 0) {
                console.log("No Orders Found.");
                setRows([]);
                setLoading(false);
                return;
            }
            orders.forEach((order, index) => {
                order.id = index;
                order.key = index;
                if(order.order_date) {
                    order.order_date = order.order_date.replace(/T/g,' ')
                }
            })
            // console.log(orders)
            // let pos = [...new Set(orders.map(obj => obj.internal_po_number))];
            // let po_out = {};
            // for(let po of pos) {
            //     let filtered = orders.filter(obj => obj.internal_po_number === po);
            //     po_out[po] = {rows: filtered, total: filtered.reduce((a, b) => a + b.quantity, 0)};
            // }
            // let out = [];
            // let i = 0;
            // for(let po in po_out) {
            //     let po_obj = po_out[po];
            //     out.push({
            //         id: i,
            //         vendor_name: po_obj.rows[0].vendor_name,
            //         internal_po_number: po,
            //         order_date: po_obj.rows[0].order_date.replace(".000Z", ""),
            //         pieces: po_obj.total,
            //         quantity: po_obj.quantity,
            //         warehouse: po_obj.warehouse,
            //         built: po_obj.built
            //         // order_status: po_obj.rows[0].order_status
            //     })
            //     i++;
            // }
            setRows(orders);
            setLoading(false);
        }
        getOrders();
        return () => {
            setRows([])
        }
    }, [refresh, company, warehouse, token, setLoading])

    const sendASNs = async() => {
        props.setLoading(true);
        let vendors = [...new Set(rows.map(obj => obj.vendor_name))]
        await bulkHelpers.sendASNs(vendors, company, token) // Hard coded for just Lowes or Petco right now, fix later
        props.setLoading(false);
    }

    const downloadASNs = async() => {
        props.setLoading(true);
        let res = await bulkHelpers.exportASNs(company, token);
        await downloadFile(res.data);
        props.setLoading(false);
    }

    
    const downloadFile = async(array) => {
        if(array.length <= 0) return;
        let csv_arr = [Object.keys(array[0])];
        for(let row of array) {
            let out_row = [];
            let keys = Object.keys(row);
            for(let key of keys) {
                out_row.push(row[key])
            }
            csv_arr.push(out_row.join(","));
        }
        let csv = csv_arr.join("\n");
        let blob = new Blob([csv], {type: "text/csv"})
        let uri = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", `READY_SHIPMENTS_${new Date().toISOString().substring(0, 10)}.csv`);
        document.body.appendChild(a);
        a.click();
    }

    const sendToAcumaticaOnHold = async() => {
        props.setLoading(true);
        props.toast.promise(sendToAcumaticaOnHoldHelper(), {
            success: "Orders Sent to Acumatica",
            error: "Failed to Send Orders to Acumatica"
        })
    }
        
    const sendToAcumaticaOnHoldHelper = async() => {
        let error = null;
        try {
            for(let row of props.selectedRows) {
                await bulkHelpers.resendToAcumatica(props.company + "_bulk", {internal_po_number: row.internal_po_number, warehouse: "Kentucky", vendor_name: row.vendor_name, vendor: row.vendor_name, on_hold: true}, props.token)
            }
        }
        catch(err) {
            console.log(err);
            error=err;
        }
        finally {
            props.setLoading(false);
            if(error) throw error;
        }
    }



    return(
        <Box display="flex" flexDirection="column">

        <Box> 
            <Box sx={{mt:"20px"}}>

            {props.status === "New" && rows.length > 0?
            <Box>
                    <Button onClick={()=>setOpenExport(true)} variant="contained">Export Orders</Button>
                    <DecisionImport {...props} />
            </Box>
            : null}
            {props.status === "New" && props.selectedRows.length > 0 ?
                    <Button sx={{ml:"20px"}} onClick={()=>sendToAcumaticaOnHold()} className={`${props.company} ${props.warehouse} secondary`} variant="contained">Send to Acumatica On Hold</Button>
            : null}
        
            {props.status === "Not Shipped" ? 
                <Box>
                    {openBulkUpdate ? <BulkUpdatePopup open={openBulkUpdate} handleClose={setOpenBulkUpdate} {...props} />: null}
                    <Button onClick={() => setOpenBulkUpdate(true)} variant="contained" className={`${props.company} ${props.warehouse} primary`}>Bulk Update</Button>
                </Box>
            : null}
            </Box>
            {props.selectedRows.length > 0 && props.selectedRows.every(obj => obj.vendor_name === props.selectedRows[0].vendor_name) ? 
                <Box display="flex" style={{marginTop:"50px"}}>
                    {props.buttons.map((obj, index) => {
                        return(<Button key={index} className={`${props.company} ${props.warehouse} ${obj.type}`} sx={{mr:"15px"}} variant="contained" onClick={obj.function}>{obj.text}</Button>)
                    })}
                    <Button onClick={()=>setOpenDetails(true)} className={`${props.company} ${props.warehouse}`} color="info" variant="contained">View Details</Button>
                </Box>
            : null}
            {props.isShipped && props.selectedRows.length === 0 ? 
                <Box>
                    <Button disabled={props.isLoading} onClick={()=>sendASNs()} style={{marginTop:"20px", marginLeft:"20px"}} variant="contained">Send Ready ASNs</Button>
                    <Button disabled={props.isLoading} onClick={()=>downloadASNs()} style={{marginTop:"20px", marginLeft:"20px"}} variant="contained">Export Ready ASNs</Button>
                </Box> : null}
            <Box display="flex">
                <Button variant='contained' onClick={() => setRefresh(!refresh)} color="success" sx={{ml: props.isMobile ? '20px' : '800px', mt: '20px', maxHeight: '45px'}}>
                    Refresh
                </Button>
            </Box>
            <BulkTable {...props} rows={rows} />
            {openDetails ? <BulkOrderDetails openDetails={openDetails} setOpenDetails={setOpenDetails} selected={props.selectedRows} {...props} /> : null}
            {openExport ? <BulkExportPopup open={openExport} handleClose={setOpenExport} orders={rows} {...props} /> : null}
        </Box>
    </Box>
    )
    
}

export default BulkOrderTableTemplate;