import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import WarrantyInsertForm from './WarrantyInsertForm'
import WarrantyTable from './WarrantyTable';
import orderHelpers from '../../helpers/orders';
import vendorHelpers from '../../helpers/vendors';
import OrderDetails from './OrderDetails';
import moment from 'moment';

function WarrantyInsert(props) {

    const [date, setDate] = useState(null);
    const [poNumber, setPoNumber] = useState('');
    const [originalPoNumber, setOriginalPoNumber] = useState('');
    const [vendor, setVendor] = useState(null);
    const [dropshipVendorList, setDropshipVendorList] = useState([]);
    const [bulkVendorList, setBulkVendorList] = useState([]);
    const [allVendorList, setAllVendorList] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [action, setAction] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [street, setStreet] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState(null);
    const [zipCode, setZipCode] = useState('');
    const [originalSku, setOriginalSku] = useState(null);
    const [skuList, setSkuList] = useState([]);
    const [sku, setSku] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [lotNumber, setLotNumber] = useState('');
    const [reason, setReason] = useState(null);
    const [warehouseNotes, setWarehouseNotes] = useState('');
    const [notes, setNotes] = useState('');
    const [fastShipping, setFastShipping] = useState(null);
    const [type, setType] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [orderDetails, setOrderDetails] = useState([]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [direction, setDirection] = useState(null);
    const [orderStatus, setOrderStatus] = useState(1);
    
    useEffect(() => {

        Promise.all([vendorHelpers.getVendors(props.company, props.token), vendorHelpers.getBulkVendors(props.company + "_bulk", props.token)]).then(responses => {
            const dropshipVendorList = responses[0].vendors;
            setDropshipVendorList(dropshipVendorList);
            const bulkVendorList = responses[1].vendors;
            setBulkVendorList(bulkVendorList);
            const allVendorSet = new Set([...dropshipVendorList, ...bulkVendorList]);
            const allVendorList = Array.from(allVendorSet);
            setAllVendorList(allVendorList);
        })

        handleDateChange(moment());
        getDropshipSkus();
        getWarrantyOrderDetails();

    }, []);

    useEffect(() => {

        if (selectedOrder.internal_po_number) {
            updateSelectedOrder(selectedOrder.internal_po_number);
        }

    }, [orderDetails]);

    const handleChange = (event) => {
        let func = `set${event.target.name[0].toUpperCase() + event.target.name.substring(1)}`;
        eval(func)(event.target.value);
    }

    const handleDateChange = (date) => {
        if (date !== null){

            setDate({formatted: date.format("YYYY-MM-DD"), full: date});
        } else {
            setDate(date);
        }
    }

    const getDropshipSkus = async () => {
        props.setLoading(true);
        try {
            const dropshipSkusResponse = await orderHelpers.getDropshipItems(props.company, props.token);
            const dropshipSkus = dropshipSkusResponse.items;
            const mappedSkus = dropshipSkus.map(value => ({ value: value, name: value}));
            setSkuList(mappedSkus);
        }
        catch(err) {
            console.log(err);
        }
        props.setLoading(false);
    }

    const getWarrantyOrderDetails = async () => {
        props.setLoading(true);
        try {
            const today = new Date().toISOString().slice(0, 10);
            const warranty_orders = await orderHelpers.getWarrantyOrderDetails(props.company, {order_by: 'warranty_id', order_status: 1, start_date: today}, props.token);
            setOrderDetails(warranty_orders);
        }
        catch(err) {
            console.log(err);
        }
        props.setLoading(false);
    }

    const requiredFields = [
        { field: 'order_date', message: 'Date is required.' },
        { field: 'internal_po_number', message: 'PO Number is required.' },
        { field: 'warranty_issued', message: 'Action is required.'},
        { field: 'reason', message: 'Reason is required.'},
        { field: 'customer_name', message: 'Name is required.'},
        { field: 'street', message: 'Street is required.'},
        { field: 'city', message: 'City is required.'},
        { field: 'state', message: 'State is required.'},
        { field: 'zip_code', message: 'Zip Code is required.'},
        { field: 'original_sku', message: 'Original SKU is required.'},
        { field: 'internal_sku', message: 'SKU is required.'},
        { field: 'quantity', message: 'Quantity is required.'},
        { field: 'notes', message: 'Notes is required.'}
    ]
    
    const checkValidity = (orderInfo) => {
        let errorMessages = [];
        
        for (const { field, message } of requiredFields) {
            if (!orderInfo[field]) {
                errorMessages.push(message);
            }
        }
    
        if (errorMessages.length > 0) {
            const error_message = errorMessages.join('\n');
            props.toast.error(error_message);
            return false;
        }
        return true;
    };

    const setWarrantyOrder = async () => {
        props.setLoading(true);
        const orderInfo = {
            order_date: date !== null ? date.formatted : null,
            internal_po_number: poNumber !== "" ? poNumber : null,
            external_po_number: originalPoNumber !== "" ? originalPoNumber : null,
            order_status: orderStatus !== "" ? orderStatus : null,
            order_type: type !== "" ? type : null,
            vendor: vendor !== "" ? vendor : null,
            warehouse: warehouse !== "" ? warehouse : null,
            warranty_issued: action !== "" ? action : null,
            original_sku: originalSku !== "" ? originalSku?.value ? originalSku?.value : originalSku : null,
            internal_sku: sku !== "" ? sku?.value ? sku?.value : sku : null,
            quantity: quantity !== "" ? quantity : null,
            lot_number: lotNumber !== "" ? lotNumber : null,
            reason: reason !== "" ? reason : null, 
            fast_shipping: fastShipping !== "" ? fastShipping : null, 
            customer_name: name !== "" ? name : null,
            email: email !== "" ? email : null,
            street: street !== "" ? street : null,
            street_2: street2 !== "" ? street2 : null,
            city: city !== "" ? city : null,
            state: state !== "" ? state : null,
            zip_code: zipCode !== "" ? zipCode : null,
            warehouse_notes: warehouseNotes !== "" ? warehouseNotes : null,
            notes: notes !== "" ? notes : null,
        }
        if (!checkValidity(orderInfo)) {
            props.setLoading(false);
            return;
        }
        try {
            const res = await orderHelpers.setWarrantyOrder(props.company, orderInfo, props.token);
            if(res.success) {
                props.toast.success('Saved Warranty Order!');
                getWarrantyOrderDetails();
                handleDateChange(moment());
                setPoNumber('');
                setOrderStatus(1);
                setOriginalPoNumber('');
                setType(null);
                setVendor(null);
                setWarehouse(null);
                setAction('');
                setReason(null);
                setFastShipping(null);
                setName('');
                setEmail('');
                setStreet('');
                setStreet2('');
                setCity('');
                setState(null);
                setZipCode('');
                setOriginalSku(null);
                setSku(null);
                setQuantity('');
                setLotNumber('');
                setWarehouseNotes('');
                setNotes('');
            } else {
                props.toast.error(res.message);
            }
        }
        catch(err) {
            props.toast.error('Failed to Save Warranty Order!');
        }
        finally {
            props.setLoading(false);
        }
    }

    const setMultipleItemWarrantyOrder = async () => {
        props.setLoading(true);
        const orderInfo = {
            order_date: date !== null ? date.formatted : null,
            internal_po_number: poNumber !== "" ? poNumber : null,
            external_po_number: originalPoNumber !== "" ? originalPoNumber : null,
            order_status: orderStatus !== "" ? orderStatus : null,
            order_type: type !== "" ? type : null,
            vendor: vendor !== "" ? vendor : null,
            warehouse: warehouse !== "" ? warehouse : null,
            warranty_issued: action !== "" ? action : null,
            original_sku: originalSku !== "" ? originalSku : null,
            internal_sku: sku !== "" ? sku : null,
            quantity: quantity !== "" ? quantity : null,
            lot_number: lotNumber !== "" ? lotNumber : null,
            reason: reason !== "" ? reason : null, 
            fast_shipping: fastShipping !== "" ? fastShipping : null, 
            customer_name: name !== "" ? name : null,
            email: email !== "" ? email : null,
            street: street !== "" ? street : null,
            street_2: street2 !== "" ? street2 : null,
            city: city !== "" ? city : null,
            state: state !== "" ? state : null,
            zip_code: zipCode !== "" ? zipCode : null,
            warehouse_notes: warehouseNotes !== "" ? warehouseNotes : null,
            notes: notes !== "" ? notes : null,
        }
        if (!checkValidity(orderInfo)) {
            props.setLoading(false);
            return;
        }
        try {
            const res = await orderHelpers.setWarrantyOrder(props.company, orderInfo, props.token);
            if (res.success) {
                props.toast.success('Saved Warranty Order!');
                getWarrantyOrderDetails();
                setOriginalSku(null);
                setSku(null);
                setQuantity('');
                setLotNumber('');
                setWarehouseNotes('');
                setNotes('');
            } else {
                props.toast.error(res.message);
            }
        }
        catch(err) {
            props.toast.error('Failed to Save Warranty Order!');
        }
        finally {
            props.setLoading(false);
        }
    }

    const updateSelectedOrder = (internal_po_number) => {
        const itemInfo = [];
        orderDetails.forEach((orderLine) => {
            if (orderLine.internal_po_number === internal_po_number) {
                itemInfo.push({original_sku: orderLine.original_sku,
                                internal_sku: orderLine.internal_sku,
                                quantity: orderLine.quantity,
                                lot_number: orderLine.lot_number,
                                warehouse_notes: orderLine.warehouse_notes,
                                notes: orderLine.notes,
                                warranty_id: orderLine.warranty_id
                });
            }
        })
        const orderInfo = orderDetails.find(orderDetail => orderDetail.internal_po_number === internal_po_number);
        if (!orderInfo) {
            console.log('unexpectedly failed to find selected order');
        } else {
            const warranty_object = {
                order_date: orderInfo.order_date,
                order_status: orderInfo.order_status,
                internal_po_number: orderInfo.internal_po_number,
                external_po_number: orderInfo.external_po_number,
                order_type: orderInfo.order_type,
                vendor: orderInfo.vendor,
                warehouse: orderInfo.warehouse,
                warranty_issued: orderInfo.warranty_issued,
                reason: orderInfo.reason, 
                fast_shipping: orderInfo.fast_shipping, 
                customer_name: orderInfo.customer_name,
                email: orderInfo.email,
                street: orderInfo.street,
                street_2: orderInfo.street_2,
                city: orderInfo.city,
                state: orderInfo.state,
                zip_code: orderInfo.zip_code,
                items: itemInfo
            }
            setSelectedOrder(warranty_object);
        }
    }

    const setOpenDetails = async(selectedOrder = {}) => {
        if(Object.keys(selectedOrder)?.length === 0 ) {
            setSelectedOrder(selectedOrder);
            setSelectedItemIndex(0);
        }
        else {
            const this_internal_po_number = selectedOrder.row?.internal_po_number;
            updateSelectedOrder(this_internal_po_number);
            let selectedTabIndex = -1;

            orderDetails.forEach((orderLine) => {
                if (orderLine.internal_po_number === this_internal_po_number) {
                    selectedTabIndex++;
                    if (orderLine.internal_sku === selectedOrder.row?.internal_sku) {
                        setSelectedItemIndex(selectedTabIndex);
                    }
                }
            })
        }
    }

    const updateOrderState = (orderInfo) => {
        const newOrders = [];
        orderDetails.forEach(orderLine => {
            if (orderLine.internal_po_number === orderInfo.internal_po_number) {
                newOrders.push({
                    ...orderLine,
                    ...orderInfo
                });
            } else {
                newOrders.push(orderLine);
            }
        })
        setOrderDetails(newOrders);
    }

    const updateItemState = (itemInfo) => {
        const newOrders = [];
        orderDetails.forEach(orderLine => {
            if (orderLine.warranty_id === itemInfo.warranty_id) {
                newOrders.push({
                    ...orderLine,
                    ...itemInfo
                });
            } else {
                newOrders.push(orderLine);
            }
        })
        setOrderDetails(newOrders);
    }

    const exportOrders = async() => {
        const today = new Date();
        const formattedDate = `${today.getMonth() + 1}.${today.getDate()}.${today.getFullYear().toString().slice(-2)}`;
        let data = [
            ["order_date", "internal_po_number", "original_po_number" , "vendor", "original_warehouse", "action", "reason", 
            "fast_shipping", "original_sku", "sku", "quantity", "lot_number", "customer_name", "email", "street", "street_2", "city", 
            "state", "zip_code", "warehouse_notes", "notes"].join(",")
        ];
        for(let order of orderDetails) {
            data.push(
                [order.order_date, order.internal_po_number, order.external_po_number, order.vendor, order.warehouse, 
                order.warranty_issued, order.reason, order.fast_shipping, order.original_sku, order.internal_sku, 
                order.quantity, order.lot_number, order.customer_name, order.email, order.street, order.street_2, order.city, 
                order.state, order.zip_code, order.warehouse_notes, order.notes].join(",")
            );
        }
        let csv = data.join("\n");
        let uri = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", `${formattedDate} WarrantyInsertExport.csv`);
        document.body.appendChild(a);
        a.click();
    }

    return(
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
            <OrderDetails 
                dropshipVendors = {dropshipVendorList}
                bulkVendors = {bulkVendorList}  
                allVendors = {allVendorList}
                skuList = {skuList}
                updateOrderState = {updateOrderState}
                updateItemState = {updateItemState}
                {...props} 
                direction = {direction} 
                setSelectedItemIndex = {setSelectedItemIndex}
                tab = {selectedItemIndex} 
                selectedOrder = {selectedOrder} 
                setOpenDetails = {setOpenDetails}
            />
            <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{paddingLeft: 2}}>
                <h1>Insert Warranty Order</h1>
                <WarrantyInsertForm
                    isMobile = {props.isMobile}
                    poNumber = {poNumber}
                    name = {name}
                    email = {email}
                    street = {street}
                    street2 = {street2}
                    city = {city}
                    zipCode = {zipCode}
                    lotNumber = {lotNumber}
                    warehouseNotes = {warehouseNotes}
                    notes = {notes}
                    originalPoNumber = {originalPoNumber}
                    dropshipVendors = {dropshipVendorList}
                    bulkVendors = {bulkVendorList}  
                    allVendors = {allVendorList}
                    skuList = {skuList}
                    originalSku = {originalSku}
                    setOriginalSku = {setOriginalSku}
                    sku = {sku}
                    setSku = {setSku}
                    handleChange = {handleChange}
                    handleDateChange = {handleDateChange}
                    date = {date?.full}
                    reason = {reason}
                    setReason = {setReason}
                    type = {type}
                    setType = {setType}
                    vendor = {vendor}
                    setVendor = {setVendor}
                    quantity = {quantity}
                    setQuantity = {setQuantity}
                    state = {state}
                    setState = {setState}
                    setWarrantyOrder = {setWarrantyOrder}
                    setMultipleItemWarrantyOrder = {setMultipleItemWarrantyOrder}
                    fastShipping = {fastShipping}
                    setFastShipping = {setFastShipping}
                    warehouse = {warehouse}
                    setWarehouse = {setWarehouse}
                    action = {action}
                    setAction = {setAction}
                />
                <WarrantyTable 
                    isMobile = {props.isMobile}
                    orderDetails = {orderDetails}
                    setOpenDetails = {setOpenDetails}
                    exportOrders = {exportOrders}
                />
            </Box>
        </Box>
    )
}

export default WarrantyInsert;